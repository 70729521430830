import {DDMMYYYYFormatting} from '@/util';

export default class courseDayGroupedByDate {

  constructor(courseDays, list) {
    this.courseDays = courseDays;
    this.list = list;
  }

  groupCourseDayList() {
    let list = { ...this.list };
    if (!list) return {};

    this.courseDays.forEach(courseDay => {
      let group = courseDay.date;
      if (group in list) {
        list[group].items.push(courseDay);
        list[group].counts.courses_count += 1;
        if (courseDay.candidates_count === 0) list[group].counts.zero_candidates_count += 1;
        if (courseDay.candidates_count >= 1) list[group].counts.least_one_candidates_count += 1;
      } else {
        list[group] = {
          label: DDMMYYYYFormatting(`${courseDay.date}`),
          groupDate: courseDay.date,
          groupDateFormatted: DDMMYYYYFormatting(`${courseDay.date}`),
          items: [courseDay],
          counts: {
            courses_count: 1,
            zero_candidates_count: courseDay.candidates_count === 0 ? 1 : 0,
            least_one_candidates_count: courseDay.candidates_count >= 1 ? 1 : 0,
          }
        };
      }
    });

    return list;
  }
}
