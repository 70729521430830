<template lang="pug">
  transition(name="fadeLeft")
    .cd-sidebar(v-if="sidebarState")
      .sidebar__filters
        .select-city
          base-select(
            :class="['choose-city', {active: $route.query[COURSE_DAY_FILTERS.CITY]}]"
            placeholder="Choose city"
            placement="bottom-end"
            :options="crmCourseLocations"
            :value="courseCity"
            @input="selectCity"
            slug-key='ID'
            slug-value='name'
            searchable
            text
            hide-arrow
          )
            template(v-slot:before)
              span.location-icon
            template(v-slot:option="selectProps")
              span.option-slug {{ selectProps.option.code }}
              span {{ selectProps.option.name }}
          img(src="@/assets/img/ui/close.svg" @click="selectCity(undefined)" :class="{'active': courseCityQuery}").close-icon.close-icon__city
        .divider
        appDatepicker(
          label="Choose date"
          :value="courseDateQuery"
          :class="['select-date', {'active': courseDateQuery}]"
          @input="selectDate"
          @change="selectDate"
          @clear="selectDate(undefined)"
          no-title
          :min="getMinDate"
          :max="getMaxDate"
        )
          template(v-slot:activator)
            span.calendar-icon
      .sidebar__tabs
        sidebar-tabs(:key="`${activeTab}-${$route.query.course_day}`" @click:tab="onTabsChange($event)")
      .preloader(v-if="loading")
        v-progress-linear(:indeterminate="true")
      .sidebar__list
        .cd__preloader(v-if="loading" data-test="cd-sidebar-preloader")
          v-skeleton-loader(type="text" v-for="loader in [1,2,3,4,5,6,7,8,9,10]" :key="loader").cd-sidebar__preloader-item
        .cd__content(
          v-infinite-scroll="loadMore"
          infinite-scroll-disabled="disabledLoadingMore"
          infinite-scroll-distance="10"
          infinite-scroll-immediate-check="true"
        )
          groupedList.cm-sidebar__body
            template(v-for="courseDay in courseDaysListGrouped")
              groupedListTitle(:key="courseDay.groupDate")
                template(v-slot:title)
                  .course-date
                    .calendar
                      span.month {{ courseDay.groupDate | getMonthDutch }}
                      span.date {{ courseDay.groupDate | getDayDutch }}
                    span {{ courseDay.groupDateFormatted | DOWDutchDDD }} {{ courseDay.groupDateFormatted }}
                  courseSidebarCounts(:counts="courseDay.counts")
                groupedListItem(
                  data-test="cm-course"
                  v-for="item in orderByCity(courseDay.items)"
                  @click="select(item)"
                  :active="isActive(item.id)"
                  :key="`${item.id}`"
                )
                  course-day-sidebar-item(:item="item")
                    .set-zoom-id(v-if="featureFlags.NCRM_3360" @click.stop="")
                      course-day-set-zoom-i-d(:item="item" @update="needToUpdate(true)")
                    .edit-course(@click.stop="")
                      crudDialog(
                        :item="item"
                        @click:remove="removeCourseDay"
                        @create="needToUpdate(true)"
                        @update="needToUpdate"
                        editable
                      )
            template(v-if="!courseDaysList.length && !loading")
              p.course-list__empty No data available
</template>

<script>
import { mapGetters } from "vuex";
import { convertToDefaultBackendFormat } from '@/util'
import { DOWDutchDDD, getDayDutch, getMonthDutch } from '@/util/filters'
import { COURSE_DAY_FILTERS, COURSE_DAY_SIDEBAR_TABS, TABS_QUERY, SIDEBAR_PAGE_QUERY, SIDEBAR_PAGE_SIZE } from './core/courseDay-const'
import api from '@/services/api/api-courses'
import CourseService from '@/app/admin/models/courseService'
import CourseDayGrouped from './core/courseDayGrouped'

import PaginationModel from '@/models/paginationModel'
import paginationMixin from '@/mixins/pagination.mixin'
import errorsMixin from '@/mixins/errors.mixin'
import InfinityScroll from '@/components/global/infinityScroll.vue'

import featureFlagsMixin from '@/mixins/featureFlags.mixin'
import courseService from '@/app/admin/models/courseService'
import courseAPI from '@/services/api/api-courses'

export default {
  name: 'CourseDaySidebar',

  filters: {
    DOWDutchDDD, getMonthDutch, getDayDutch
  },

  mixins: [paginationMixin, errorsMixin, featureFlagsMixin],

  data: () => ({
    loading: true,
    courseDaysList: [],
    courseDaysListGrouped: [],
    courseDate: '',
    courseCity: null,
    activeTab: '',
    pagination: null,
    COURSE_DAY_SIDEBAR_TABS,
    TABS_QUERY,
    COURSE_DAY_FILTERS,
    sideBarLoadSize: 50
  }),

  created() {
    if (this.$route.query[TABS_QUERY]) this.activeTab = this.$route.query[TABS_QUERY]
    if (this.$route.query.date) this.courseDate = this.$route.query.date
    if (this.$route.query[COURSE_DAY_FILTERS.CITY]) this.courseCity = +this.$route.query[COURSE_DAY_FILTERS.CITY]
  },

  async mounted() {
    await this.loadData({}, this.$route.query)
    if (this.$route.query.course_day) await this.getCourseDayInfo(this.$route.query.course_day)
  },

  computed: {
    ...mapGetters({
      crmCourseLocations: 'crmCourseCities/items',
      sidebarState: 'user/sidebar'
    }),
    activeItem() {
      return this.courseDaysList.find(item => item.id === +this.$route.query.course_day)
    },
    courseDateQuery() {
      return this.$route.query[COURSE_DAY_FILTERS.DATE]
    },
    courseCityQuery() {
      return this.$route.query[COURSE_DAY_FILTERS.CITY]
    },
    disabledLoadingMore() {
      if (!this.pagination) return true
      if (this.loading) return true
      if (!this.pagination.next) return true
      return false
    },
    getMinDate() {
      if (!this.activeTab || this.isFutureTab) return convertToDefaultBackendFormat(new Date(Date.now()))
      else return ''
    },

    getMaxDate() {
      let date = new Date();
      if (this.isPastTab) return convertToDefaultBackendFormat(date.setDate(date.getDate() - 1))
      else return ''
    },

    isPastTab() {
      return !!(this.activeTab && this.activeTab === COURSE_DAY_SIDEBAR_TABS.PAST);
    },

    isFutureTab() {
      return !!(this.activeTab && this.activeTab === COURSE_DAY_SIDEBAR_TABS.FUTURE);
    }
  },

  methods: {
    orderByCity: function (arr) {
      return arr.slice().sort(function (a, b) {
        return a[COURSE_DAY_FILTERS.CITY] - b[COURSE_DAY_FILTERS.CITY];
      });
    },

    async getCourseDayInfo(id) {
      try {
        this.loading = true
        let res = await new courseService(courseAPI).getCourseDay(id)
        await this.selectCourse(res)
      } catch (error) {
        this.$notify({text: 'Error', type: 'error'})
      } finally {
        this.loading = false
      }
    },

    async loadData({more = false}, query) {
      try {
        this.loading = true
        let _query = {...this.$route.query, ...query}
        _query.page = _query[SIDEBAR_PAGE_QUERY]
        _query.size = _query[SIDEBAR_PAGE_SIZE]
        let {results, pagination} = await this.loadTabData(_query)
        if (more) this.courseDaysList.push(...results)
        else this.courseDaysList = results
        this.courseDaysListGrouped = await new CourseDayGrouped(this.courseDaysList).groupCourseDayList()
        pagination = {...pagination,  ...this.getDefaultPageSize() }
        this.pagination = new PaginationModel(this.setPaginationParams(pagination))
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      } finally {
        this.loading = false
      }
    },

    async loadTabData(query) {
      let res;
      if (this.isPastTab) res = await new CourseService(api).courseDaysPast(query, this.sideBarLoadSize)
      else res = await new CourseService(api).courseDaysFuture(query, this.sideBarLoadSize)
      return res
    },

    isActive(itemID) {
      return +this.$route.query.course_day === itemID
    },

    async selectCourse(item) {
      await this.setQuery({page: 1}, true)
      this.$emit('select:course', item)
    },

    async removeCourseDay() {
      if (this.$route.query.course_day) await this.setQuery({course_day: undefined})
      await this.needToUpdate()
      await this.selectCourse(null)
    },

    async setQuery(query, saveQuery = true) {
      let _query = query
      if (saveQuery) _query = {...this.$route.query, ...query}

      await this.$router.replace({query: _query}).catch(()=>{});
    },

    async select(item) {
      if (+this.$route.query.course_day === item.id) {
        return
        // unselect course type
        // await this.setQuery({course_day: undefined})
        // this.selectCourse(null)
      } else {
        await this.setQuery({course_day: item.id})
        await this.selectCourse(item)
      }
    },

    reset() {
      this.courseDaysList = [];
      this.courseDaysListGrouped = [];
    },

    async needToUpdate(withPageDataUpdate = false) {
      await this.loadData({})
      if (withPageDataUpdate) this.$emit('updated:sidebar', true)
    },

    async selectDate(value) {
      if (this.$route.query[COURSE_DAY_FILTERS.DATE] === value) return

      if (!value) {
        await this.setQuery({ [COURSE_DAY_FILTERS.DATE]: undefined })
        await this.needToUpdate()
        return
      }

      try {
        if (this.loading) return
        this.loading = true
        await this.setQuery({ [COURSE_DAY_FILTERS.DATE]: convertToDefaultBackendFormat(value), [SIDEBAR_PAGE_QUERY]: undefined });
        await this.needToUpdate()
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      } finally {
        this.loading = false
      }
    },

    async selectCity(value) {
      if (!value) {
        this.courseCity = null
        await this.setQuery({city: undefined})
        await this.needToUpdate()
        return
      }

      try {
        if (this.loading) return
        this.loading = true
        this.courseCity = value
        await this.setQuery({city: value, [SIDEBAR_PAGE_QUERY]: undefined});
        await this.needToUpdate()
      } catch (error) {
        this.processError(error, {redirectName: this.$ROUTER_NAMES.LOGIN_CRM})
      } finally {
        this.loading = false
      }
    },

    onTabsChange(payload) {
      this.activeTab = payload
      this.reset();
      this.needToUpdate()
    },

    async loadMore() {
      if (!this.pagination) return
      if (this.loading) return
      await this.onLoadMoreClick()
      await this.loadData({more: true})
    },

    async setPaginationQuery() {
      await this.$router.replace({query: {...this.$route.query, [SIDEBAR_PAGE_QUERY]: this.pagination.page, [SIDEBAR_PAGE_SIZE]: this.pagination.size }}).catch(()=>{});
    },

    updateActiveItemCounts(counts) {
      let activeItem = this.courseDaysList.find(item => item.id === this.activeItem.id)
      let activeItemInGroup = this.courseDaysListGrouped[activeItem.date].items.find(item => item.id === activeItem.id)
      activeItem.will_come_candidates_count = counts.will_come_candidates_count
      activeItem.will_not_come_candidates_count = counts.will_not_come_candidates_count
      activeItemInGroup.will_come_candidates_count = counts.will_come_candidates_count
      activeItemInGroup.will_not_come_candidates_count = counts.will_not_come_candidates_count
    }
  },

  components: {
    infinityScroll: InfinityScroll,
    CourseDaySidebarItem: () => import('./components/sidebar/CourseDaySidebarItem'),
    crudDialog: () => import('../course_managment/components/curssusen/cm_configuration/CourseDayCrud.vue'),
    courseCounts: () => import('../course_managment/components/CMCourseCountsCurss.vue'),
    courseSidebarCounts: () => import('./components/sidebar/CourseDaySidebarCounts.vue'),
    groupedList: () => import('@/components/global/grouped-list/GroupedList.vue'),
    groupedListTitle: () => import('@/components/global/grouped-list/GroupedListTitle.vue'),
    groupedListItem: () => import('@/components/global/grouped-list/GroupedListItem.vue'),
    sidebarTabs: () => import('./components/sidebar/CourseDaySidebarTabs.vue'),
    appDatepicker: () => import('@/components/global/fields/appDatepicker'),
    baseSelect: () => import('@/components/global/actions/BaseSelect.vue'),
    CourseDaySetZoomID: () => import('./components/zoom/CourseDaySetZoomID.vue')
  }
}
</script>

<style lang="scss" scoped>
.cd-sidebar {
  display: flex;
  flex-direction: column;
  width: 255px;
  min-width: 255px;
  height: 100%;
  border-right: 1px solid $border-color;
  position: relative;

  @include media("<=tablet") {
    display: none;
    width: calc(100% - 49px);
    position: absolute;
    background-color: white;
    right: 0;
    z-index: 2;
    transition: all 0.15s ease-in-out;
  }

  &.open {
    display: flex;
    transition: all 0.15s ease-in-out;
  }

  .sidebar__list {
    height: 100%;
    width: 100%;
    background-color: #f4f7f9;
    display: flex;
    flex-direction: column;
    position: relative;
    overflow-y: scroll;

    .cd__preloader {
      display: flex;
      flex-direction: column;
      padding: 20px 25px;
      order: 10;

      &-item {
        padding: 8px 0;
      }
    }

    .cd__content {
      display: flex;
      flex-direction: column;
      order: 1;
      border-top: 1px solid $border-color;

      .cm-sidebar__body {
        .course-list__empty {
          display: flex;
          justify-content: center;
          align-items: center;
          padding: 30px 10px 10px 10px;
          font-family: $font-roboto;
          font-size: 12px;
          color: $label-color;
        }
      }
    }
  }
}

.sidebar__filters {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 5px 10px;
  height: 53px;
  border-bottom: 1px solid $border-color;

  @include media("<=tablet") {
    padding: 0 25px;
  }

  .select-city {
    display: flex;
    position: relative;
  }

  .select-date {
    @include media("<=tablet") {
      width: 100%;
    }
  }

  .divider {
    background-color: rgba($label-color, 0.25);
    width: 1px;
    right: 0;
    height: 45px;
    top: -10px;
  }
}

.course-date {
  display: flex;
  flex-direction: row;
  align-items: center;
  border-radius: 4px;

  .calendar {
    display: flex;
    flex-direction: column;
    border-radius: 4px;
    box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.06);
    background-color: #ffffff;
    width: 36px;
    height: 36px;
    margin-right: 5px;

    .month {
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $font-roboto;
      border-top-right-radius: 4px;
      border-top-left-radius: 4px;
      font-size: 8px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #ffffff;
      background-color: #e42883;
      min-height: 12px;
      text-transform: uppercase;
    }

    .date {
      height: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: $font-roboto;
      font-size: 16px;
      font-weight: bold;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      text-align: center;
      color: #232428;
    }
  }

  span {
    font-family: $font-roboto;
    font-size: 14px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #232428;
  }
}
</style>

<style lang="scss">
.cd-sidebar {
  .grouped-list__title {
    .grouped-list__title-text {
      display: flex !important;
      flex-direction: row;
      align-items: center;
      justify-content: space-between;
      padding: 12px 10px !important;
      background-color: transparent !important;
      border-bottom: none;

      span {
        color: $text-color;
      }
    }

    .grouped-list {
      box-shadow: 0 2px 8px 0 rgba(0, 0, 0, 0.06);
      background-color: #FFFFFF;

      .grouped-list__item {
        border: none;
        background: none !important;
        &_active {
          .course-date__code {
            color: $primary-color;
          }
          .course-date__title {
            color: $primary-color;
          }
        }
      }
    }
  }

  .cd-sidebar-item {
    &.hovered {
      .edit-course, .set-zoom-id {
        opacity: 1;
        transition: all 0.15s ease-in-out;
      }
    }

    .set-zoom-id {
      right: 40px !important;
    }

    .edit-course, .set-zoom-id {
      opacity: 0;
      transition: all 0.15s ease-in-out;
      position: absolute;
      left: auto;
      right: 10px;
      top: auto;
      bottom: auto;
    }
  }
}

.sidebar__filters {
  min-height: 41px;

  .app-datepicker {
    width: auto;
    height: 30px;
    margin-right: 0;
    min-width: 120px;
    background-color: transparent;
    margin-bottom: 0;

    &.active {
      .calendar-icon {
        transition: all 0.15s ease-in-out;
        background: url("../course_leaders/assets/calendar-black.svg") no-repeat;
      }
    }
    .calendar-icon {
      min-width: 15px;
      min-height: 15px;
      margin-right: 5px;
      background-size: contain;
      transition: all 0.15s ease-in-out;
      background: url("../course_leaders/assets/calendar-grey.svg") no-repeat;
    }
  }

  .choose-city {
    display: flex;
    cursor: pointer;

    .v-popover {
      display: flex;
      .app-input__wrapper {
        background: transparent;

        .app-input__before {
          display: flex;
          align-items: center;
          justify-content: center;
        }

        input {
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
          max-width: 90px;
          margin-right: 20px;
        }

        input::placeholder {
          font-size: 11px;
          min-width: 60px;
          font-weight: bold;
          font-stretch: normal;
          font-style: normal;
          line-height: normal;
          letter-spacing: normal;
          color: $placeholder-color;
        }
      }
    }

    .location-icon {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 15px;
      height: 15px;
      cursor: pointer;
      background-size: contain;
      transition: all 0.15s ease-in-out;
      background: url("../../../../assets/img/ui/location-grey.svg") no-repeat;
    }

    &.active {
      .location-icon {
        transition: all 0.15s ease-in-out;
        background: url("../../../../assets/img/ui/location-black.svg") no-repeat;
      }
    }
  }

  .close-icon {
    top: -5px;

    &__city {
      right: 5px;
    }

    &.active {
      display: flex;
    }
  }
}

.sidebar__tabs {
  position: relative;
  border-bottom: 1px solid $border-color;
  background-color: #FFFFFF;
}

.v-skeleton-loader__text {
  margin-bottom: 20px;
}
</style>
